import "../css/header.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const handleAddToCart = () => {
    navigate("/cartpage");
  };
  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <img alt="" src="/wordpress/head.png" />
        </div>
        <div className="navDiv">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA">
                  SHOP
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="menu-item">
                <Link to="/aboutUs">Support</Link>
              </li>
              <li className="menu-shop" onClick={handleAddToCart}>
                <FontAwesomeIcon icon={faCartShopping} />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;

import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function ContactUs() {
  const checkValue = (e) => {
    var value = e.target.value;
    console.log("You selected " + value);
  };

  return (
    <div className="main-container">
      <Header />

      <div className="contactAll">
        {/* right */}
        <div className="contactRight">
          <div className="contactRightTxt">
            <h2>Ask a question before you buy..</h2>
            <p>
              See if a Ready Made Website is the right option for you and your
              business.
            </p>
            <div className="contactRightForm">
              <input type="text" placeholder="Name" />
              <input type="text" placeholder="Email" />
            </div>
            <div className="interestDiv">
              <h4>I'm interested in..</h4>
              <div className="labelPut" style={{ marginTop: "-4%" }}>
                <label class="radio-label">
                  <input
                    type="radio"
                    name="myRadio"
                    value="option1"
                    className="myRadio"
                  />
                </label>
                <p> Wellness Ready Made Website</p>
              </div>
              <div className="labelPut">
                <label class="radio-label">
                  <input
                    type="radio"
                    name="myRadio"
                    value="option2"
                    className="myRadio"
                  />
                </label>
                <p> Empower Ready Made Website</p>
              </div>

              <textarea type="text" placeholder="What's your question?" />
              <div className="btnWid">
                <button>Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;

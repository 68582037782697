import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSquareCheck,
  faRocket,
  faImage,
  faCloudArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import "../css/main.css";
import productData from "../Datas/productData";

function Main() {
  const navigate = useNavigate();

  const toDetails = (id) => {
    navigate(`/shopDetails/${id}`);
    window.scrollTo(0, 0);
  };
  const toThree = () => {
    const scrollPosition = window.innerHeight * 2.3;
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />

      <div className="mainBox">
        {/* first */}
        {productData
          .filter((brand) => brand.id === 5)
          .map((brand) => (
            <div className="twoDiv">
              <div className="mainFirst">
                <div className="firstTxt09">
                  <p className="topP">
                    Easy To Use + Professional + Conversion Focused
                  </p>
                  <h1>{brand.name}</h1>
                  <p className="ready">
                    Premium Wordpress website templates to take the overwhelm
                    out of building your own website.
                  </p>
                  <p className="desTxt">{brand.description}</p>
                  <div className="imgContext11Btn09" onClick={toThree}>
                    <button>View Collection</button>
                  </div>
                </div>
                <div>
                  <Link to={`/`}>
                    <img src={brand.images[0]} alt="twoImg" />
                  </Link>
                </div>
              </div>
            </div>
          ))}

        {/* sec */}
        <div className="threeDiv">
          <div className="mainThreeFirst">
            <div className="main2">
              <img src="/wordImg/main2.png" alt="twoImg" />
            </div>
            <div className="secTxt">
              <p>GET NOTICED + GROW YOUR AUDIENCE</p>
              <h1>Ready Made Websites are perfect for your business if..</h1>
              <div className="threeUl">
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faSquareCheck} />
                    You are just starting out.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faSquareCheck} />
                    You want to look professional and slick, but you’re not
                    ready to invest in a custom site.
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faSquareCheck} />
                    You need a website, but you’re not technical.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faSquareCheck} />
                    You’re not a designer and struggle with keeping everything
                    consistent.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faSquareCheck} />
                    You just want to get up and running online as soon as
                    possible.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* three */}
        <div className="fiveDiv">
          <div>
            <p>for Wordpress + Divi</p>
            <h3>Ready Made Websites</h3>
            <p>ready made websites showcase</p>
          </div>
          <div className="fiveDivImg">
            {productData
              .filter((brand) => brand.id >= 1 && brand.id <= 2)
              .map((product) => (
                <div className="imgContext" key={product.id}>
                  <img alt="" src={product.images[0]} />
                  <h3>{product.name}</h3>
                  <p>
                    <em>{product.types}</em>
                  </p>
                  <h4>{product.description}</h4>
                  <div
                    className="imgContext11Btn77"
                    onClick={() => toDetails(product.id)}
                  >
                    <button>View {product.name}</button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="threeDiv44">
          <div className="mainThreeFirst44">
            <div className="main244">
              <img src="/wordImg/main5.png" alt="twoImg" />
            </div>
            <div className="secTxt44">
              <p>Not techy, no problem!</p>
              <h1>
                My walkthrough Install To Launch 🚀 course will guide you
                through every step of the way.
              </h1>
              <h4>
                My Ready Made Wordpress Websites come with lifetime access to my
                walkthrough course. It takes you step-by-step through how to
                change all aspects of your Ready Made Website, so it fits
                seamlessly with business.
              </h4>
              <h4>
                You get access to my private Facebook group for support, where
                you can come along to my ‘Website Clinic’ weekly sessions to get
                help and advice.
              </h4>
              <h4>
                <strong>Plus,</strong> you have 12 months access to my ever
                expanding video tutorial library.
              </h4>
              <div className="imgContext11Btn88" onClick={toThree}>
                <button>View ready made websites</button>
              </div>
            </div>
          </div>
        </div>
        <div className="mainThreeFirst55">
          <div className="secTxt55">
            <p>Take a peek behind the scenes</p>
            <h1>Easy to customise to match your business/brand</h1>
            <h4>
              With my WordPress Ready Made Websites it is easy to add your own
              logo, business colours and your own text and images.
            </h4>
            <div className="imgContext11Btn88" onClick={toThree}>
              <button>shop Collection</button>
            </div>
          </div>
          <div className="main255">
            <img src="/mainImg/image1.png" alt="twoImg" />
          </div>
        </div>

        <div className="et_section_regular">
          <div className="et_pb_row_6">
            <p>Look professional + get noticied</p>
            <h2>3 simple steps to launching your Ready Made Website</h2>
          </div>
          <div className="et_pb_row_7">
            <div className="et_pb_column_12">
              <FontAwesomeIcon icon={faCloudArrowDown} />
              <h4>Get Instant Access</h4>
              <p>
                On purchase get <strong>instant access</strong> to your chosen
                ready made website and <strong>install with one click</strong>
              </p>
            </div>
            <div className="et_pb_column_12">
              <FontAwesomeIcon icon={faImage} />
              <h4>Customise to make it your own</h4>
              <p>
                Follow my <strong>step by step walkthrough</strong> course to
                add your business colours, content and more.
              </p>
            </div>
            <div className="et_pb_column_121">
              <FontAwesomeIcon icon={faRocket} />
              <h4>Launch</h4>
              <p>
                Be confident to
                <strong>launch, promote and refer potential clients</strong>
                course to to your new website.
              </p>
            </div>
          </div>
          <div className="svgDivTxt">
            <p>
              With one of my Ready Made Websites, customising and launching your
              new website is quick & easy.
            </p>
            <p>No coding needed!</p>
            <button onClick={toThree}>SHOP THE COLLECTION</button>
          </div>
        </div>

        <div className="fiveDiv11">
          <div>
            <p>ready made websites showcase</p>
            <h3>See how other business owners are using Ready Made Websites</h3>
          </div>
          <div className="fiveDivImg11">
            <div className="imgContext11">
              <img alt="" src="/wordImg/main7.png" />
              <h4>Shore Coaching </h4>
            </div>
            <div className="imgContext11">
              <img alt="" src="/wordImg/main8.png" />
              <h4>Space To See </h4>
            </div>
          </div>
          <div className="imgContext11Btn" onClick={toThree}>
            <button>Shop ready made websites</button>
          </div>
        </div>

        <div className="vc_line">
          <div>
            <p>FAQs</p>
            <h3>Got some questions?</h3>
          </div>
          <div className="flexSide">
            <div className="et_pb_row">
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  What website platform are the templates built on?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  Are there any other costs involved with the website templates?{" "}
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  Where can I get website hosting?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  Where can I get a domain name?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  Can I change the colours and fonts on the website themes?{" "}
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  Can you help me customise my purchased theme?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
            <div className="et_pb_column">
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  What support do I get with the website templates?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module ">
                <h5 className="et_pb_toggle_title">
                  Can I change the colours and fonts on the website themes?{" "}
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module_img ">
                <img alt="" src="/wordImg/main6.png" />
              </div>
            </div>
          </div>
          <div className="et_pb_text_inner11">
            <div className="innerTxt">
              <p>
                <em>
                  Disclosure: Some of the links in this post are ‘affiliate
                  links.’ This means if you click on the link and purchase the
                  item, I will receive an affiliate commission. I never recomend
                  anything that I do not use myself either on my own website or
                  withi my bisiness.
                </em>
              </p>
            </div>
          </div>
        </div>
        <div className="et_pb">
          <div className="et_pb_column_24">
            <div className="et_pb_txt_inner">
              <p>Over On Instagram</p>
            </div>
            <div className="et_line"></div>
          </div>
          <div className="et_line_img">
            <ul className="et_line_ul">
              <li>
                <img alt="" src="/wordImg/fin1.jpg" />
              </li>
              <li>
                <img alt="" src="/wordImg/fin2.jpg" />
              </li>
              <li>
                <img alt="" src="/wordImg/fin3.jpg" />
              </li>
              <li>
                <img alt="" src="/wordImg/fin4.jpg" />
              </li>
              <li>
                <img alt="" src="/wordImg/fin5.jpg" />
              </li>
              <li>
                <img alt="" src="/wordImg/fin6.jpg" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Support() {
  return (
    <div className="main-container">
      <Header />

      <div className="contactAll">
        <div className="leftBox">
          <h1>Ready Made Websites Wordpress Templates Help & Support</h1>
          <p>
            You will get access to my<strong> Install to Launch 🚀</strong>{" "}
            walkthrough course, as well as a growing video tutorial library, in
            the Support Hub.
          </p>
          <p>
            All support is handled through my private Facebook group – Ready
            Made Websites Support. Post your questions there or search the group
            content for answers. I also hold a weekly ‘Website Chat’ live at
            10am (BST) on a Friday.
          </p>
        </div>
        {/* right */}
        <div className="contactRight">
          <div className="contactRightTxt">
            <img alt="" src="/wordImg/support.jpg" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Support;

import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledDeleteIcon = styled(FontAwesomeIcon)({
  cursor: "pointer",
  textAlign: "center",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    navigate("/checkout");
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * (item.quantity || 1);
    }, 0);
  };

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="basketShop">
          <div className="cart-page">
            <div className="cart-header">
              <h1>In Your Basket</h1>
            </div>
            <div className="cart-total">
              <div className="cart-items">
                {cartItems.length === 0 ? (
                  <h3 style={{ color: "black", textAlign: "center" }}>
                    Your Cart is Empty!
                  </h3>
                ) : (
                  <StyledTableContainer component={Paper}>
                    <Table aria-label="shopping cart table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="left">Price</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="left">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartItems.map((item) => {
                          const product = getProductById(item.id);
                          return (
                            <TableRow key={item.id}>
                              <StyledTableCell component="th" scope="row">
                                <StyledImage
                                  src={product.images[0]}
                                  alt="Product"
                                  style={{ paddingRight: "30px" }}
                                />
                                {product.name}
                              </StyledTableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                {product.price}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                  width: "17%",
                                }}
                              >
                                <input
                                  type="number"
                                  value={item.quantity || 1}
                                  className="cartPlus"
                                  onChange={(e) =>
                                    handleInputChange(item.id, e)
                                  }
                                  min="0"
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ verticalAlign: "middle" }}
                              >
                                <StyledDeleteIcon
                                  icon={faXmark}
                                  size="lg"
                                  onClick={() => removeFromCart(item.id)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                )}
              </div>
              <div className="midBasket">
                <h2 className="basketDiv">Basket totals</h2>
              </div>

              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">Subtotal</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalTh">Total(inc VAT)</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <button className="checkout-button" onClick={toCheckOut}>
                      Proceed to checkout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;

import React, { useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/wellness.css";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);

  const handleAddToCart = () => {
    addToCart(product.id);
    navigate("/cartpage");
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        {/* 产品详情部分 */}
        <div className="twoDiv">
          <div className="mainFirst">
            <div>
              <Link to={`/`}>
                <img src={product.images[2]} alt="twoImg" />
              </Link>
            </div>
            <div className="firstTxt">
              <h1>{product.name}</h1>
              <p className="ready">Ready Made WordPress Website To DIY</p>
              <p className="priceShow">{product.price}</p>
              <p className="desTxt">{product.detail}</p>
              <p className="desTxt">
                Ideal for online service based businesses.
              </p>
              <button className="bbbuy" onClick={handleAddToCart}>
                BUY NOW
              </button>
            </div>
          </div>
        </div>

        {/* 更多产品相关信息 */}
        <div className="firstDescription22">
          <div className="mainTopThree">
            <div className="glsr-summary-stars22">
              <div className="glsr-star-rating glsr-stars">
                <img alt="" src="/wordpress/xxx.webp" />
              </div>
              <div className="main_reviews_based_on">Therapists</div>
            </div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-star-rating glsr-stars">
              <img alt="" src="/wordpress/xxx.webp" />
            </div>
            <div className="main_reviews_based_on">Consultants</div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-star-rating glsr-stars">
              <img alt="" src="/wordpress/xxx.webp" />
            </div>
            <div className="main_reviews_based_on">Coaches</div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-star-rating glsr-stars">
              <img alt="" src="/wordpress/xxx.webp" />
            </div>
            <div className="main_reviews_based_on">COPYWRITERS</div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-star-rating glsr-stars">
              <img alt="" src="/wordpress/xxx.webp" />
            </div>
            <div className="main_reviews_based_on">Virtual Assistants</div>
          </div>
        </div>

        {/* {productData
          .filter((product) => product.id === 1)
          .map((product) => ( */}
        <div className="twoDiv" key={product.id}>
          <div className="mainFirst">
            <div>
              <Link to={`/shopDetails/${product.id}`}>
                <img src={product.images[3]} alt="twoImg" />
              </Link>
            </div>
            <div className="firstTxt">
              <h1>{product.first}</h1>
              <ul>
                {product.list.slice(0, 9).map((listItem, index) => (
                  <li key={index}>{listItem}</li>
                ))}
              </ul>
              <h5>
                <strong>Marketing Add-on:</strong>
              </h5>
              <ul>
                {product.list.slice(9, 11).map((listItem, index) => (
                  <li key={index}>{listItem}</li>
                ))}
              </ul>
              <h5>
                <strong>+ Plus: You’ll also get…</strong>
              </h5>
              <ul className="lastLi">
                {product.list.slice(11, 14).map((listItem, index) => (
                  <li key={index}>{listItem}</li>
                ))}
              </ul>
              <button className="bbbuy">BUY NOW</button>
            </div>
          </div>
        </div>
        {/* ))} */}
        {/* 其他信息 */}
        <div className="linerDiv">
          <div className="mainFirst1212">
            <div className="firstTxt12">
              <h1>{product.sec}</h1>
              <div className="doSelf">
                <div>
                  <h3>Do It Yourself</h3>
                </div>
                <p className="desTxt1">{product.des1}</p>
                <p className="priceShow">{product.price1}</p>
                <p className="et_pb_text_inner">{product.notes1}</p>
                <div className="greenBtn">
                  <button>BUY NOW</button>
                </div>
              </div>
            </div>
            <div className="linerImg">
              <Link to={`/shopDetails/${product.id}`}>
                <img src={product.images[4]} alt="twoImg" />
              </Link>
            </div>
          </div>
        </div>

        {/* 其他部分 */}
        <div className="fiveDiv">
          <div>
            <p>ready made websites showcase</p>
            <h3>How people are using Wellness for their business</h3>
          </div>
          <div className="fiveDivImg">
            <div className="imgContext">
              <img alt="" src="/wordImg/main7.png" />
            </div>
            <div className="imgContext">
              <img alt="" src="/wordImg/main8.png" />
            </div>
          </div>
        </div>

        {/* 常见问题部分 */}
        <div className="vc_line">
          <div>
            <p>FAQs</p>
            <h3>Got some questions?</h3>
          </div>
          <div className="flexSide">
            <div className="et_pb_row">
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  What website platform are the templates built on?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  Are there any other costs involved with the website templates?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  Where can I get website hosting?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  Where can I get a domain name?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  Can I change the colours and fonts on the website themes?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  Can you help me customise my purchased theme?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
            <div className="et_pb_column">
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  What support do I get with the website templates?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module">
                <h5 className="et_pb_toggle_title">
                  Can I change the colours and fonts on the website themes?
                </h5>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="et_pb_module_img">
                <img alt="" src={product.images[5]} />
              </div>
            </div>
          </div>
          <div className="et_pb_text_inner">
            <div className="innerTxt">
              <p>
                <em>
                  Disclosure: Some of the links in this post are ‘affiliate
                  links.’ This means if you click on the link and purchase the
                  item, I will receive an affiliate commission. I never
                  recommend anything that I do not use myself either on my own
                  website or within my business.
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;

const productData = [
  // icons
  {
    id: 1,
    name: "Wellness",
    first: "What’s included",
    sec: "Wellness Purchase Options",
    description:
      "Wellness is a WordPress Ready Made Website that’s perfect for service based business with a fresh, feminine feel..",
    types: "feminine | wellness | beauty | health | service based business",
    des1: "Your content goes here. Edit or remove this text inline or in the module Content settings. You can also style every aspect of this content in the module Design settings and even apply custom CSS to this text in the module Advanced settings.",
    price1: "£357.00",
    price: "£357.00",
    preprice: "£225.00",
    detail:
      "Wellness is a fresh, clean, Wordpress Ready Made Website to DIY. With a feminine edge and minimal look it has been specifically designed and built for conversion, turning your visitors into fans.",
    jifenMoney: "£4.95",
    notes1:
      "Please Note: This Ready Made Website only works with Divi. You must purchase a Divi license for these WordPress templates to work. Due to the nature of a digital download, all sales are final.",
    list: [
      "Homepage",
      "About",
      "Services",
      "Packages",
      "FAQs",
      "Contact",
      "Book A Call",
      "Blog",
      "Instagram",
      "Email Opt-in",
      "Thank you page",
      "Lifetime access to my step-by-step Install To Launch 🚀 walkthrough video course to get you up and running",
      "Lifetime access to my private Facebook group Ready Made Website Support and my weekly ‘Website Chat’ for support",
      "12 months access to an ever-expanding video library to help you continue improving and evolving your site",
    ],
    images: [
      "/wordImg/main3.png",
      "/wordpress/01.png",
      "/details/detail1.png",
      "/details/detail12.jpg",
      "/details/detail13.png",
      "/details/detail14.png",
    ],
  },
  {
    id: 2,
    name: "Empower",
    first: "What’s included",
    sec: "Empower Purchase Options",
    types: "instructors | trainers | business | service-based businesses",
    des1: "Your content goes here. Edit or remove this text inline or in the module Content settings. You can also style every aspect of this content in the module Design settings and even apply custom CSS to this text in the module Advanced settings.",
    price1: "£357.00",
    notes1:
      "Please Note: This Ready Made Website only works with Divi. You must purchase a Divi license for these WordPress templates to work. Due to the nature of a digital download, all sales are final.",
    description:
      "Empower is a Wordpress Ready Made Website that’s perfect for service based business with a strong, bold look and feel..",
    price: "£357.00",
    preprice: "£250.00",
    detail:
      "Empower is a clean, Bold, feminine Wordpress Ready Made Website specifically designed and built for fitness based businesses to DIY.",
    jifenMoney: "£6.25",
    list: [
      "Homepage",
      "About",
      "Services",
      "Packages",
      "FAQs",
      "Contact",
      "Book A Call",
      "Blog",
      "Instagram",
      "Email Opt-in",
      "Thank you page",
      "Lifetime access to my step-by-step Install To Launch 🚀 walkthrough video course to get you up and running",
      "Lifetime access to my private Facebook group Ready Made Website Support and my weekly ‘Website Chat’ for support",
      "12 months access to an ever-expanding video library to help you continue improving and evolving your site",
    ],

    images: [
      "/wordImg/main4.png",
      "/wordpress/102.png",
      // "/wordpress/pow1.png",
      // "/wordpress/pow2.png",
      // "/wordpress/pow3.png",
      "/details/detail2.png",
      "/details/detail22.png",
      "/details/detail23.png",
      "/details/detail24.png",
    ],
  },
  {
    id: 3,
    name: "Wellness Purchase Options",
    description:
      "Your content goes here. Edit or remove this text inline or in the module Content settings. You can also style every aspect of this content in the module Design settings and even apply custom CSS to this text in the module Advanced settings.",
    notes:
      "Please Note: This Ready Made Website only works with Divi. You must purchase a Divi license for these WordPress templates to work. Due to the nature of a digital download, all sales are final.",
    price: "£357.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 19th Sep) - 10am to 5pm",
      "1 DAY COURSE - (Thu 10th Oct) - 10am to 5pm",
    ],
    images: ["/wordpress/01.png"],
  },
  {
    id: 4,
    name: "Tinting Masterclass Course",
    description:
      "Learn how to brighten a client’s look and frame the face with this tinting and brow shaping course. This beauty course is not brand specific which means you have the freedom to choose which brand you want to work with!",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/wordImg/main7.png"],
  },
  {
    id: 5,
    name: "Ready Made Websites",
    description:
      "Whether you are yet to start your business or it’s early days, get online and start making sales with my professionally and intentionally designed, strategy focused, WordPress Ready Made Websites.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
    ],
    images: ["/wordImg/main1.png"],
  },
  {
    id: 6,
    name: "XD Volume Lash Masterclass Course",
    description:
      "Russian 2D, 3D, 4D, 5D, 6D, 7D, 8D Volume – the latest trend in the eyelash industry.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/images/10006.jpg"],
  },
  {
    id: 7,
    name: "Micropigmentation Semi Permanent Makeup Course",
    description:
      "Micropigmentation, also known as a permanent cosmetic tattoo, involves using a mechanical hand tool controlled by machine to implant pigment into the dermal layer of the skin. The non surgical procedure is performed on the eyebrows, lips and for eyeliner.",
    price: "£2,199.00",
    preprice: " £2,299.00",
    jifen: "2199 Loyalty Points ",
    jifenMoney: "£109.95",
    size: [
      "5 DAY COURSE - (Mon 21st Oct, Tue 22nd Oct, Tue 22nd Oct, Wed 23rd Oct, Thu 24th Oct, Fri 25th Oct) - 9:30am to 4:30pm",
    ],
    images: ["/images/10007.jpg"],
  },
  {
    id: 8,
    name: "Eyebrow Micropigmentation Course",
    description:
      "Micropigmentation, also known as a permanent cosmetic tattoo, involves using a mechanical hand tool controlled by machine to implant pigment into the dermal layer of the skin. The non surgical procedure is performed on the eyebrows, lips and for eyeliner.",
    price: "£1,800.00",
    preprice: " £1,800.00",
    jifen: "1800 Loyalty Points ",
    jifenMoney: "£90.00",
    size: [
      "3 DAY COURSE - (Mon 30th Sep, Tue 01st Oct, Wed 02nd Oct) - 9:30am to 4:30pm",
    ],
    images: ["/images/10008.jpg"],
  },
  {
    id: 9,
    name: "Threading Course",
    description:
      "Threading is a traditional hair removal technique that will keep you at the top of your game with your clients. The technique originates from the Eastern world, using a simple thread to remove hair in short lines from the follicle. You will learn to thread facial hair.",
    price: "£99.00",
    preprice: "£225.00",
    jifen: "99 Loyalty Points ",
    jifenMoney: "£4.95",
    size: ["HALF DAY COURSE - (Wed 03rd Jul) - 10am to 1pm"],
    images: ["/images/10009.jpg"],
  },
  {
    id: 10,
    name: "Waxing (warm & hot wax) Course",
    description:
      "You will learn to complete waxing techniques; full leg, bikini, underarm, facial including eyebrows using warm wax.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/images/10010.jpg"],
  },
  {
    id: 11,
    name: "Supremo Panther Black Scrub Set",
    description:
      "Introducing our new line of luxury salon scrubs, designed for the fashion-forward professional who values both comfort and style. These scrubs are crafted from a premium, stretchy material that moves with you, ensuring ease and flexibility throughout your busy day.",
    price: "£49.99",
    preprice: "£49.99",
    jifen: "50 Loyalty Points ",
    jifenMoney: "£2.50",
    size: ["MED (12)", "L (14)", "XL (16)"],
    images: ["/images/10011.jpg"],
  },
  {
    id: 12,
    name: "Supremo Blushing Nude Scrub Set",
    description:
      "Introducing our new line of luxury salon scrubs, designed for the fashion-forward professional who values both comfort and style. These scrubs are crafted from a premium, stretchy material that moves with you, ensuring ease and flexibility throughout your busy day.",
    price: "£49.99",
    preprice: "£125.99",
    jifen: "50 Loyalty Points ",
    jifenMoney: "£2.50",
    size: ["SML (10)", "MED (12)", "L (14)", "XL (16)"],
    images: ["/images/10012.jpg"],
  },
];

export default productData;

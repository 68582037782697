import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";

const CheckOut = () => {
  const { cartItems } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country/Region is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!address) newErrors.address = "Street address is required";
    if (!city) newErrors.city = "Town / City is required";
    if (!postalCode) newErrors.postalCode = "Postcode is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert("Order placed!");
      navigate("/paysuccess");
    }
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  return (
    <>
      <Header />
      <div className="checkAll">
        <div className="main-containerCheck">
          <div className="cart-header">
            <p>Ready Made Websites Checkout</p>
          </div>
          <div className="check-custom-checkout-page">
            <div className="left">
              <div className="check-order-info">
                <h3>Billing details</h3>
                <form>
                  <div className="firstLast">
                    <div className="form-group form-group-half">
                      <label htmlFor="first-name">First name *</label>
                      <input
                        type="text"
                        id="first-name"
                        name="first-name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      {errors.firstName && (
                        <p className="error">{errors.firstName}</p>
                      )}
                    </div>
                    <div className="form-group form-group-half">
                      <label htmlFor="last-name">Last name *</label>
                      <input
                        type="text"
                        id="last-name"
                        name="last-name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                      {errors.lastName && (
                        <p className="error">{errors.lastName}</p>
                      )}
                    </div>
                  </div>
                  <div className="firstLast">
                    <div className="form-group form-group-half">
                      <label htmlFor="country">Country/Region *</label>
                      <ReactFlagsSelect
                        selected={selected}
                        onSelect={(code) => {
                          setSelected(code);
                          setCountry(code);
                        }}
                        className="check-select"
                      />
                      {errors.country && (
                        <p className="error">{errors.country}</p>
                      )}
                    </div>
                    <div className="form-group form-group-half">
                      <label htmlFor="address">Street address *</label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                      {errors.address && (
                        <p className="error">{errors.address}</p>
                      )}
                    </div>
                  </div>
                  <div className="firstLast">
                    <div className="form-group form-group-half">
                      <label htmlFor="city">Town / City *</label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                      {errors.city && <p className="error">{errors.city}</p>}
                    </div>
                    <div className="form-group form-group-half">
                      <label htmlFor="postalCode">Postcode *</label>
                      <input
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        required
                      />
                      {errors.postalCode && (
                        <p className="error">{errors.postalCode}</p>
                      )}
                    </div>
                  </div>
                  <div className="firstLast">
                    <div className="form-group">
                      <label htmlFor="phone">Phone number *</label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      {errors.phone && <p className="error">{errors.phone}</p>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email address *</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="notes">Notes (optional)</label>
                    <textarea
                      id="notes"
                      name="notes"
                      rows="4"
                      placeholder="Notes about your order, e.g. special notes for delivery."
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>

            <div className="right">
              <div className="check-order-summary">
                {cartItems.length > 0 && (
                  <div className="cart-summary">
                    <div className="rightSum">
                      <h2 className="basketDiv">Your order</h2>
                      <table className="summary-details">
                        <thead className="sumTbody">
                          <tr className="sumTr">
                            <th className="subtotalCheck">Product</th>
                            <th className="headTotal">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody className="sumTbody">
                          {cartItems.map((item) => {
                            const product = getProductById(item.id);
                            return (
                              <tr className="sumTr" key={item.id}>
                                <th className="goodName">
                                  {product.name}
                                  <strong>&nbsp;x {item.quantity}</strong>
                                </th>
                                <th className="subtotalTxt">
                                  £
                                  {(
                                    parseFloat(product.price.replace("£", "")) *
                                    item.quantity
                                  ).toFixed(2)}
                                </th>
                              </tr>
                            );
                          })}
                          <tr className="sumTr">
                            <th className="subtotalCheck">Subtotal</th>
                            <th className="subtotalTxt">
                              £{calculateTotal().toFixed(2)}
                            </th>
                          </tr>
                          <tr className="sumTr">
                            <th className="subtotalCheck">Total (inc VAT)</th>
                            <th className="subtotalTxt">
                              £{calculateTotal().toFixed(2)}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <div className="rightBotDiv">
                        <div className="cartDebit">
                          <p>Credit Card or Debit Card (Stripe)</p>
                        </div>
                        <div className="cartInputDiv">
                          <p>
                            Pay with your credit card or debit card via Stripe.
                          </p>
                          <div className="cardNumber">
                            <label>Card Number *</label>
                            <input
                              type="text"
                              placeholder="1234 1234 1234 1234"
                              required
                            />
                          </div>
                          <div className="cartData">
                            <div className="expiryData">
                              <label>Expiry Date *</label>
                              <input type="text" placeholder="MM/YY" required />
                            </div>
                            <div className="cartCode">
                              <label>Card Code (CVC) *</label>
                              <input type="text" placeholder="CVC" required />
                            </div>
                          </div>
                        </div>
                        <button
                          className="check-checkout-button"
                          onClick={handlePlaceOrder}
                        >
                          Place order
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
